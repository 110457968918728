import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0"></Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Terms and Conditions</h1>
            <ShortLine />

            <p>
              <b></b>These Terms and Conditions act as a contractual agreement
              (&#8220;Agreement&#8221;) between you and Chicken-Yard.net
              (“Chicken Yard”, “us”, “we”, “our”), and applies to your use of this
              website. (“Site”). If you do not agree to be bound by the terms of
              this Agreement as detailed herein, please do not use or access the
              website. YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD AND
              LEGALLY COMPETENT TO ENTER INTO THIS AGREEMENT.
            </p>
            <p>
              <strong>1. PRIVACY POLICY.</strong> We respect your privacy. A
              complete statement of our current privacy policy can be found by
              clicking <Link to="/privacy-policy">here</Link>. The privacy
              policy is expressly incorporated into this Agreement by reference.
            </p>
            <p>
              <strong>2. LICENSE.</strong> We grant you a non-exclusive,
              non-sublicensable, non-transferable license and right to use and
              access the Site. You understand and agree you have no right to
              modify, edit, create derivative works from, distribute, sell,
              rent, share, or republish any information provided on the Site
              without our express consent. You further understand no ownership
              right in the content on this Site is being granted to you.
            </p>
            <p>
              <strong>3. VIOLATIONS.</strong> If you materially breach any term
              of this Agreement, we may, in our sole discretion, terminate your
              access to the Site. We reserve the right to seek all remedies
              available by law and in equity for such breaches.
            </p>
            <p>
              <strong>4. GOVERNING LAW AND VENUE.</strong> This Agreement shall
              be construed in accordance with and governed by the laws of
              California and the United States without reference to their rules
              regarding conflicts of law. You hereby irrevocably consent to the
              exclusive jurisdiction and venue of the courts in Sacramento,
              California in all disputes arising out of or related to the use of
              the Site.
            </p>
            <p>
              <strong>5. COMMUNICATION.</strong> When you contact us or sign up
              to receive our newsletter, you consent to receive communications
              from us electronically. You agree that any such communication via
              email shall constitute proper written communication in compliance
              with any and all legal notice requirements.{" "}
            </p>
            <p>
              <strong>6. USER CONTENT.</strong> By posting, uploading,
              displaying, performing, transmitting, or otherwise distributing
              information or other content (“User Content”) to the Site, you
              grant Chicken Yard, its owner, affiliates, officers, directors,
              employees, consultants, agents, and representatives a worldwide,
              royalty-free, non-exclusive, perpetual (for the duration of the
              applicable copyright) license to use the User Content in
              connection with the Internet business of Chicken Yard, its
              affiliates, officers, directors, employees, consultants, agents,
              and representatives, including without limitation, a right to
              copy, distribute, transmit, publicly display, publicly perform,
              reproduce, edit, translate, and reformat User Content. You will
              not be compensated for any User Content. By posting User Content
              on the Site, you warrant and represent that you own the rights to
              the User Content or are otherwise authorized to post, distribute,
              display, perform, transmit, or otherwise distribute User Content.
            </p>
            <p>
              <strong>7. NO WARRANTIES.</strong> Chicken Yard HEREBY DISCLAIMS ALL
              WARRANTIES. WE ARE MAKING THE SITE AVAILABLE “AS IS” WITHOUT
              WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR
              LOSS FROM USE OF, OR INABILITY TO USE, THE SITE. TO THE MAXIMUM
              EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL
              WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT
              NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Chicken Yard DOES NOT
              WARRANT THAT THE SITE OR CONTENT OFFERED THROUGH THE SITE WILL
              MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE WILL BE
              UNINTERRUPTED OR ERROR-FREE.
            </p>
            <p>
              <strong>8. LIMITED LIABILITY.</strong> Chicken Yard DISCLAIMS ANY
              AND ALL LIABILITY (WHETHER BASED IN CONTRACT, TORT, STRICT
              LIABILITY, BREACH OF WARRANTY, OR OTHERWISE) FOR ANY INDIRECT,
              INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES ARISING OUT OF OR IN
              ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE SITE OR THIS
              AGREEMENT. Chicken Yard ’S MAXIMUM LIABILITY ARISING OUT OF OR IN
              CONNECTION WITH YOUR USE OF THIS SITE, REGARDLESS OF THE CAUSE OF
              ACTION, WILL NOT EXCEED $500 SHOULD YOU BE DECLARED THE PREVAILING
              PARTY IN A CLAIM AGAINST US.
            </p>
            <p>
              <strong>9. EXCLUSIONS AND LIMITATIONS.</strong> SOME JURISDICTIONS
              DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION
              OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
              ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTIONS 8 AND 9 MAY
              NOT APPLY TO YOU.
            </p>
            <p>
              <strong>10. SITE TERMINATION.</strong> We reserve the right to no
              longer make available all or part of the Site at any time in our
              sole discretion.{" "}
            </p>
            <p>
              <strong>11. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS.</strong>{" "}
              When accessing the Site, you agree to obey the law and to respect
              the intellectual property rights of others. Your use of the Site
              is at all times governed by and subject to laws regarding
              copyright ownership and use of intellectual property. You agree
              not to upload, download, display, perform, transmit, or otherwise
              distribute any information or content (collectively, “Content”) in
              violation of any third party’s copyrights, trademarks, or other
              intellectual property or proprietary rights. You agree to abide by
              laws regarding copyright ownership and use of intellectual
              property, and you shall be solely responsible for any violations
              of any relevant laws and for any infringements of third party
              rights caused by any Content you provide or transmit. The burden
              of proving that any Content does not violate any laws or third
              party rights rests solely with you.
            </p>
            <p>
              <strong>12. AFFILIATED SITES.</strong> Chicken Yard has no control
              over and assumes no liability for any third party websites or
              materials. Chicken Yard works with a number of partners and
              affiliates whose Internet sites may be linked within the Site.
              Because Chicken Yard has no control over the content and performance
              of these partner and affiliate sites, Chicken Yard makes no
              guarantees about the accuracy, currency, content, or quality of
              the information provided by such sites, and we assume no
              responsibility for unintended, objectionable, inaccurate,
              misleading, or unlawful content that may reside on those sites.
              Similarly, from time to time in connection with your use of the
              Site, you may have access to content items (including, but not
              limited to, websites) owned by third parties. You acknowledge and
              agree that Chicken Yard makes no guarantees about, and assumes no
              responsibility for, the accuracy, currency, content, or quality of
              this third party content, and, unless expressly provided
              otherwise, these Terms and Conditions shall govern your use of any
              and all third party content.
            </p>
            <p>
              <strong>13. PROHIBITED USES.</strong> Chicken Yard imposes certain
              restrictions on your permissible use of the Site. You are
              prohibited from violating or attempting to violate any security
              features of the Site, including, without limitation, (a) accessing
              content or data not intended for you, or logging onto a server or
              account that you are not authorized to access; (b) attempting to
              probe, scan, or test the vulnerability of the Site, or any
              associated system or network, or to breach security or
              authentication measures without proper authorization; (c)
              interfering or attempting to interfere with service to any user,
              host, or network, including, without limitation, by means of
              submitting a virus to the Site , overloading, “flooding,”
              “spamming,” “mail bombing,” or “crashing;” (d) forging any TCP/IP
              packet header or any part of the header information in any e-mail
              or in any posting using the Service; (e) attempting to modify,
              reverse-engineer, decompile, disassemble, or otherwise reduce or
              attempt to reduce to a human-perceivable form any of the source
              code used by Chicken Yard; or (f) automatically or manually scraping
              or copying the content from the Site without our consent. Any
              violation may subject you to civil and/or criminal liability.
            </p>
            <p>
              <strong>14. INDEMNITY.</strong> You agree to indemnify, defend,
              and hold harmless Chicken Yard, its affiliates, officers, directors,
              employees, consultants, agents, and representatives from any and
              all third party claims, losses, liability, damages, and/or costs
              (including reasonable attorney fees and costs) arising from your
              access to or use of the Site, your violation of these Terms and
              Conditions, or your infringement, of any intellectual property or
              other right of any person or entity. Chicken Yard will notify you
              promptly of any such claim, loss, liability, or demand, and will
              provide you with reasonable assistance, at your expense, in
              defending any such claim, loss, liability, damage, or cost.
            </p>
            <p>
              <strong>15. COPYRIGHT.</strong> All contents, excluding user
              generated content, Copyright 2017. All rights reserved.
            </p>
            <p>
              <strong>16. SEVERABILITY; WAIVER.</strong> If a court of competent
              jurisdiction finds any term or condition in this Agreement to be
              unenforceable or null, all other terms will remain unaffected and
              in full force and effect. No waiver of any breach of any provision
              of this Agreement shall constitute a waiver of any prior,
              concurrent, or subsequent breach of the same or any other
              provisions hereof, and no waiver shall be effective unless made in
              writing and signed by an authorized representative of the waiving
              party.
            </p>
            <p>
              <strong>17. NO LICENSE.</strong> Nothing contained on the Site
              should be understood as granting you a license to use any of the
              trademarks, service marks, or logos of Chicken Yard or any third
              party.
            </p>
            <p>
              <strong>18. MODIFICATIONS.</strong> Chicken Yard may, in its sole
              discretion, modify or amend this Agreement at any time. Chicken Yard
              shall post notice of any such changes on the Site and shall email
              you notice of such changes if we have an email address for you.
              Your decision to continue to use the Site upon such notice shall
              constitute your acceptance of any amendments to this document. You
              may choose to reject the amendments by terminating your use of the
              Site.{" "}
            </p>
            <p>
              Please <Link to="/contact/">contact us</Link> if you have any
              questions regarding these Terms and Conditions.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
